<template>
  <div>
    <AccordionCard :state="cards.isRoofAccessibleFromInsideState">
      <template v-slot:header>
        {{ $t('isRoofAccessibleFromInsideState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('isRoofAccessibleFromInsideState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.isRoofAccessibleFromInsideState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('annotations') }}
        </div>
        <div class="flex-column select">
          <Textarea
            v-model="project.qualityReport.isRoofAccessibleFromInsideNote"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('image_upload') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.isRoofAccessibleFromInsideAttachments"
            attachmentType="isRoofAccessibleFromInsideAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isIsRoofAccessibleFromInsideAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="isRoofAccessibleFromInsideAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isIsRoofAccessibleFromInsideAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.properSleeveUsedAtRoofPenetrationState">
      <template v-slot:header>
        {{ $t('properSleeveUsedAtRoofPenetrationState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('properSleeveUsedAtRoofPenetrationState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.properSleeveUsedAtRoofPenetrationState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('annotations') }}
        </div>
        <div class="flex-column select">
          <Textarea
            v-model="project.qualityReport.properSleeveUsedAtRoofPenetrationNote"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('image_upload') }}</div>
        <div class="flex-row">
          <div class="flex-column select">
            <div>
              {{ $t('templateAttachments') }}:
              <AttachmentGallery
                :attachments="getReportTemplateImages[68].templateAttachments"
                :deletable="false"
                :sortByName="false"
                :showFilename="false"
              ></AttachmentGallery>
            </div>
          </div>
        </div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.properSleeveUsedAtRoofPenetrationAttachments"
            attachmentType="properSleeveUsedAtRoofPenetrationAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isProperSleeveUsedAtRoofPenetrationAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="properSleeveUsedAtRoofPenetrationAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isProperSleeveUsedAtRoofPenetrationAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.underlaySealedWellAtCableEntryState">
      <template v-slot:header>
        {{ $t('underlaySealedWellAtCableEntryState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('underlaySealedWellAtCableEntryState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.underlaySealedWellAtCableEntryState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.underlaySealedWellAtCableEntryNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[69].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.underlaySealedWellAtCableEntryAttachments"
              attachmentType="underlaySealedWellAtCableEntryAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isUnderlaySealedWellAtCableEntryAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="underlaySealedWellAtCableEntryAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isUnderlaySealedWellAtCableEntryAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.underlayDamagedOrWaterStainsState">
      <template v-slot:header>
        {{ $t('underlayDamagedOrWaterStainsState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('underlayDamagedOrWaterStainsState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.underlayDamagedOrWaterStainsState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.underlayDamagedOrWaterStainsNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.labelingOfStringLinesState">
      <template v-slot:header>
        {{ $t('labelingOfStringLinesState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('labelingOfStringLinesState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.labelingOfStringLinesState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.labelingOfStringLinesNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[70].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.labelingOfStringLinesAttachments"
              attachmentType="labelingOfStringLinesAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isLabelingOfStringLinesAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="labelingOfStringLinesAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="isLabelingOfStringLinesAttachmentsUploadComplete = false"
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.pvStringCableCorrectCrossSectionState">
      <template v-slot:header>
        {{ $t('pvStringCableCorrectCrossSectionState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('pvStringCableCorrectCrossSectionState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.pvStringCableCorrectCrossSectionState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.pvStringCableCorrectCrossSectionNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.peCableCorrectlySizedState">
      <template v-slot:header>
        {{ $t('peCableCorrectlySizedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('peCableCorrectlySizedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.peCableCorrectlySizedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.peCableCorrectlySizedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cablesProperlySecuredState">
      <template v-slot:header>
        {{ $t('cablesProperlySecuredState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cablesProperlySecuredState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.cablesProperlySecuredState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.cablesProperlySecuredNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.crimpingProfessionallyDoneState">
      <template v-slot:header>
        {{ $t('crimpingProfessionallyDoneState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('crimpingProfessionallyDoneState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.crimpingProfessionallyDoneState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.crimpingProfessionallyDoneNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.correctSelectionOfPvConnectorsOnStringCableState">
      <template v-slot:header>
        {{ $t('correctSelectionOfPvConnectorsOnStringCableState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('correctSelectionOfPvConnectorsOnStringCableState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.correctSelectionOfPvConnectorsOnStringCableState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.correctSelectionOfPvConnectorsOnStringCableNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[71].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.correctSelectionOfPvConnectorsOnStringCableAttachments"
              attachmentType="correctSelectionOfPvConnectorsOnStringCableAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isCorrectSelectionOfPvConnectorsOnStringCableAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="correctSelectionOfPvConnectorsOnStringCableAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isCorrectSelectionOfPvConnectorsOnStringCableAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.allPlugConnectionsProfessionallyMadeState">
      <template v-slot:header>
        {{ $t('allPlugConnectionsProfessionallyMadeState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('allPlugConnectionsProfessionallyMadeState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.allPlugConnectionsProfessionallyMadeState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.allPlugConnectionsProfessionallyMadeNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cablesNeatlyLaidInsideBuildingState">
      <template v-slot:header>
        {{ $t('cablesNeatlyLaidInsideBuildingState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cablesNeatlyLaidInsideBuildingState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.cablesNeatlyLaidInsideBuildingState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.cablesNeatlyLaidInsideBuildingNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[72].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.cablesNeatlyLaidInsideBuildingAttachments"
              attachmentType="cablesNeatlyLaidInsideBuildingAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isCablesNeatlyLaidInsideBuildingAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="cablesNeatlyLaidInsideBuildingAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isCablesNeatlyLaidInsideBuildingAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard
      :state="cards.spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterState"
    >
      <template v-slot:header>
        {{ $t('spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="
              project.qualityReport
                .spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterState
            "
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="
                project.qualityReport
                  .spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterNote
              "
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryState">
      <template v-slot:header>
        {{ $t('surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="
              project.qualityReport.surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryState
            "
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="
                project.qualityReport.surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryNote
              "
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[73].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryAttachments"
              attachmentType="surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="
                isSurgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryAttachmentsUploadComplete
              "
              :parentId="project.id"
              parentType="surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isSurgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>
    <AccordionCard :state="cards.isConnectorSealingIntactState">
      <template v-slot:header>
        {{ $t('isConnectorSealingIntactState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('isConnectorSealingIntactState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.isConnectorSealingIntactState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.isConnectorSealingIntactNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.isSurgeProtectionBoxPresenState">
      <template v-slot:header>
        {{ $t('isSurgeProtectionBoxPresenState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('isSurgeProtectionBoxPresenState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.isSurgeProtectionBoxPresenState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.isSurgeProtectionBoxPresentNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.isSurgeProtectionAtHouseEntryState">
      <template v-slot:header>
        {{ $t('isSurgeProtectionAtHouseEntryState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('isSurgeProtectionAtHouseEntryState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.isSurgeProtectionAtHouseEntryState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>

        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.isSurgeProtectionAtHouseEntryNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    SelectButton,
    Textarea,
    // AccordionCardOptionalInput,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isUnderlaySealedWellAtCableEntryAttachmentsUploadComplete: false,
      isProperSleeveUsedAtRoofPenetrationAttachmentsUploadComplete: false,
      isLabelingOfStringLinesAttachmentsUploadComplete: false,
      isCorrectSelectionOfPvConnectorsOnStringCableAttachmentsUploadComplete: false,
      isCablesNeatlyLaidInsideBuildingAttachmentsUploadComplete: false,
      isIsRoofAccessibleFromInsideAttachmentsUploadComplete: false,
      isSurgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isUnderlaySealedWellAtCableEntryAttachmentsUploadComplete = true;
          this.isProperSleeveUsedAtRoofPenetrationAttachmentsUploadComplete = true;
          this.isLabelingOfStringLinesAttachmentsUploadComplete = true;
          this.isCorrectSelectionOfPvConnectorsOnStringCableAttachmentsUploadComplete = true;
          this.isCablesNeatlyLaidInsideBuildingAttachmentsUploadComplete = true;
          this.isSurgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryAttachmentsUploadComplete = true;
          this.isIsRoofAccessibleFromInsideAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
