<template>
  <div>
    <AccordionCard :state="cards.cablesDoNotRestOnTilesState">
      <template v-slot:header>
        {{ $t('cablesDoNotRestOnTilesState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cablesDoNotRestOnTilesState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.cablesDoNotRestOnTilesState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.cablesDoNotRestOnTilesNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cablesLaidProtectedUnderTilesState">
      <template v-slot:header>
        {{ $t('cablesLaidProtectedUnderTilesState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cablesLaidProtectedUnderTilesState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.cablesLaidProtectedUnderTilesState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.cablesLaidProtectedUnderTilesNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[59].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.cablesLaidProtectedUnderTilesAttachments"
              attachmentType="cablesLaidProtectedUnderTilesAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isCablesLaidProtectedUnderTilesAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="cablesLaidProtectedUnderTilesAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isCablesLaidProtectedUnderTilesAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cableEntryOnRoofProperlyDoneState">
      <template v-slot:header>
        {{ $t('cableEntryOnRoofProperlyDoneState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cableEntryOnRoofProperlyDoneState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.cableEntryOnRoofProperlyDoneState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.cableEntryOnRoofProperlyDoneNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[60].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.cableEntryOnRoofProperlyDoneAttachments"
              attachmentType="cableEntryOnRoofProperlyDoneAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isCableEntryOnRoofProperlyDoneAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="cableEntryOnRoofProperlyDoneAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isCableEntryOnRoofProperlyDoneAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cableLaidThroughChimneyState">
      <template v-slot:header>
        {{ $t('cableLaidThroughChimneyState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cableLaidThroughChimneyState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.cableLaidThroughChimneyState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.cableLaidThroughChimneyNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cableLaidInProtectiveSleeveAgainstUvRadiationState">
      <template v-slot:header>
        {{ $t('cableLaidInProtectiveSleeveAgainstUvRadiationState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cableLaidInProtectiveSleeveAgainstUvRadiationState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.cableLaidInProtectiveSleeveAgainstUvRadiationState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.cableLaidInProtectiveSleeveAgainstUvRadiationNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[61].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.cableLaidInProtectiveSleeveAgainstUvRadiationAttachments"
              attachmentType="cableLaidInProtectiveSleeveAgainstUvRadiationAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isCableLaidInProtectiveSleeveAgainstUvRadiationAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="cableLaidInProtectiveSleeveAgainstUvRadiationAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isCableLaidInProtectiveSleeveAgainstUvRadiationAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>
    <AccordionCard :state="cards.isModuleNotShaded2State">
      <template v-slot:header>
        {{ $t('isModuleNotShaded2State') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('isModuleNotShaded2State') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.isModuleNotShaded2State"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.isModuleNotShaded2Note"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.areModuleClampsNotTwistedState">
      <template v-slot:header>
        {{ $t('areModuleClampsNotTwistedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('areModuleClampsNotTwistedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.areModuleClampsNotTwistedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.ussWiredProperlyInCabinetNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.isChimneySweeperApprovalGiven2State">
      <template v-slot:header>
        {{ $t('isChimneySweeperApprovalGiven2State') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('isChimneySweeperApprovalGiven2State') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.isChimneySweeperApprovalGiven2State"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.isChimneySweeperApprovalGiven2Note"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[62].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.peCableProperlySecuredAttachments"
              attachmentType="peCableProperlySecuredAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isPeCableProperlySecuredAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="peCableProperlySecuredAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="isPeCableProperlySecuredAttachmentsUploadComplete = false"
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.peCableProperlySecuredState">
      <template v-slot:header>
        {{ $t('peCableProperlySecuredState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('peCableProperlySecuredState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.peCableProperlySecuredState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.peCableProperlySecuredNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[62].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.peCableProperlySecuredAttachments"
              attachmentType="peCableProperlySecuredAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isPeCableProperlySecuredAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="peCableProperlySecuredAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="isPeCableProperlySecuredAttachmentsUploadComplete = false"
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    SelectButton,
    Textarea,
    // AccordionCardOptionalInput,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      isCablesLaidProtectedUnderTilesAttachmentsUploadComplete: false,
      isCableEntryOnRoofProperlyDoneAttachmentsUploadComplete: false,
      isCableLaidInProtectiveSleeveAgainstUvRadiationAttachmentsUploadComplete: false,
      isPeCableProperlySecuredAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isCablesLaidProtectedUnderTilesAttachmentsUploadComplete = true;
          this.isCableEntryOnRoofProperlyDoneAttachmentsUploadComplete = true;
          this.isCableLaidInProtectiveSleeveAgainstUvRadiationAttachmentsUploadComplete = true;
          this.isPeCableProperlySecuredAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
