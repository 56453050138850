<template>
  <div>
    <div class="accordion" role="tablist" v-if="project && project.serviceReport">
      <div>
        <h1>{{ $t('brickAndUk') }}</h1>
        <BrickAndSubstructure :cards="cards" :project="project" />
      </div>
      <div>
        <h1>{{ $t('solarPanels') }}</h1>
        <SolarPanels :cards="cards" :project="project" />
      </div>
      <div>
        <h1>{{ $t('cableManagement') }}</h1>
        <CableManagement :cards="cards" :project="project" />
      </div>
      <div>
        <h1>{{ $t('hak') }}</h1>
        <HAK :cards="cards" :project="project" />
      </div>
      <div>
        <h1>{{ $t('zs') }}</h1>
        <ElectricMeter :cards="cards" :project="project" />
      </div>
      <div>
        <h1>{{ $t('electric_meter') }}</h1>
        <Inverter :cards="cards" :project="project" />
      </div>
      <div>
        <h1>{{ $t('locationAndCable') }}</h1>
        <InstallationLocation :cards="cards" :project="project" />
      </div>
      <div>
        <h1>{{ $t('earthingUnit') }}</h1>
        <EarthingUnit :cards="cards" :project="project" />
      </div>
      <div>
        <h1>{{ $t('internetConnection') }}</h1>
        <InternetAccess :cards="cards" :project="project" />
      </div>
      <div>
        <h1>{{ $t('wallbox') }}</h1>
        <Wallbox :cards="cards" :project="project" />
      </div>
      <div>
        <h1>{{ $t('MISC') }}</h1>
        <Misc :cards="cards" :project="project" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BrickAndSubstructure from './MobileService/BrickAndSubstructure.vue';
import SolarPanels from './MobileService/SolarPanels.vue';
import Misc from './MobileService/Misc.vue';
import EarthingUnit from './MobileService/EarthingUnit.vue';
import InstallationLocation from './MobileService/InstallationLocation.vue';
import InternetAccess from './MobileService/InternetAccess.vue';
import Wallbox from './MobileService/Wallbox.vue';
import CableManagement from './MobileService/CableManagement.vue';
import HAK from './MobileService/HAK.vue';
import Inverter from './MobileService/Inverter.vue';
import ElectricMeter from './MobileService/ElectricMeter.vue';

export default {
  name: 'MobileProjectsReport',
  components: {
    HAK,
    BrickAndSubstructure,
    SolarPanels,
    CableManagement,
    Inverter,
    ElectricMeter,
    Misc,
    Wallbox,
    InternetAccess,
    EarthingUnit,
    InstallationLocation,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      cards: {
        brickAndUkAssemblyType: {
          name: 'brickAndUkAssemblyType',
          navigateTo: this.goToCard,
          successor: 'serviceBrickCoverWidthAttachments',
          predecessor: null,
          opened: false,
          completed: this.completedState,
        },
        serviceBrickCoverWidthAttachments: {
          name: 'serviceBrickCoverWidthAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceCorrectBrickHandlingAttachments',
          predecessor: 'brickAndUkAssemblyType',
          opened: false,
          completed: this.completedState,
        },
        serviceCorrectBrickHandlingAttachments: {
          name: 'serviceCorrectBrickHandlingAttachments',
          navigateTo: this.goToCard,
          successor: 'brickAndUkA2State',
          predecessor: 'serviceBrickCoverWidthAttachments',
          opened: false,
          completed: this.completedState,
        },
        brickAndUkA2State: {
          name: 'brickAndUkA2State',
          navigateTo: this.goToCard,
          successor: 'brickAndUkBState',
          predecessor: 'serviceCorrectBrickHandlingAttachments',
          opened: false,
          completed: this.completedState,
        },
        brickAndUkBState: {
          name: 'brickAndUkBState',
          navigateTo: this.goToCard,
          successor: 'serviceBrickAndSubstructureAttachments',
          predecessor: 'brickAndUkA2State',
          opened: false,
          completed: this.completedState,
        },
        serviceBrickAndSubstructureAttachments: {
          name: 'serviceBrickAndSubstructureAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceCableAndPlugAttachedToRailStateAttachments',
          predecessor: 'brickAndUkBState',
          opened: false,
          completed: this.completedState,
        },
        serviceCableAndPlugAttachedToRailStateAttachments: {
          name: 'serviceCableAndPlugAttachedToRailStateAttachments',
          navigateTo: this.goToCard,
          successor: 'railsEarthed',
          predecessor: 'serviceBrickAndSubstructureAttachments',
          opened: false,
          completed: this.completedState,
        },
        railsEarthed: {
          name: 'railsEarthed',
          navigateTo: this.goToCard,
          successor: 'serviceCorrectCableInputAttachments',
          predecessor: 'serviceCableAndPlugAttachedToRailStateAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceCorrectCableInputAttachments: {
          name: 'serviceCorrectCableInputAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceRailOverlappingTerminationFittingAttachments',
          predecessor: 'railsEarthed',
          opened: false,
          completed: this.completedState,
        },
        serviceRailOverlappingTerminationFittingAttachments: {
          name: 'serviceRailOverlappingTerminationFittingAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceProtectedUnderBrickCableRoutingAttachments',
          predecessor: 'serviceCorrectCableInputAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceProtectedUnderBrickCableRoutingAttachments: {
          name: 'serviceProtectedUnderBrickCableRoutingAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceBrickAndSubstructureOptionalAttachments',
          predecessor: 'serviceRailOverlappingTerminationFittingAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceBrickAndSubstructureOptionalAttachments: {
          name: 'serviceBrickAndSubstructureOptionalAttachments',
          navigateTo: this.goToCard,
          successor: 'brickAndUkOptionalNotes',
          predecessor: 'serviceProtectedUnderBrickCableRoutingAttachments',
          opened: false,
          completed: this.completedState,
        },
        brickAndUkOptionalNotes: {
          name: 'brickAndUkOptionalNotes',
          navigateTo: this.goToCard,
          successor: 'serviceStringPlanAttachments',
          predecessor: 'serviceBrickAndSubstructureOptionalAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceStringPlanAttachments: {
          name: 'serviceStringPlanAttachments',
          navigateTo: this.goToCard,
          successor: 'stringPlanAvailableState',
          predecessor: 'brickAndUkOptionalNotes',
          opened: false,
          completed: this.completedState,
        },
        stringPlanAvailableState: {
          name: 'stringPlanAvailableState',
          navigateTo: this.goToCard,
          successor: 'serviceAllPanelsVisibleAttachments',
          predecessor: 'serviceStringPlanAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceAllPanelsVisibleAttachments: {
          name: 'serviceAllPanelsVisibleAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceClampAreaAttachments',
          predecessor: 'stringPlanAvailableState',
          opened: false,
          completed: this.completedState,
        },
        serviceClampAreaAttachments: {
          name: 'serviceClampAreaAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceTrayfDistanceAttachments',
          predecessor: 'serviceAllPanelsVisibleAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceTrayfDistanceAttachments: {
          name: 'serviceTrayfDistanceAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceRailOverlapAttachments',
          predecessor: 'serviceClampAreaAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceRailOverlapAttachments: {
          name: 'serviceRailOverlapAttachments',
          navigateTo: this.goToCard,
          successor: 'servicePanelBottomViewAttachments',
          predecessor: 'serviceTrayfDistanceAttachments',
          opened: false,
          completed: this.completedState,
        },
        servicePanelBottomViewAttachments: {
          name: 'servicePanelBottomViewAttachments',
          navigateTo: this.goToCard,
          successor: 'solarModuleTypePlateAttachments',
          predecessor: 'serviceRailOverlapAttachments',
          opened: false,
          completed: this.completedState,
        },
        solarModuleTypePlateAttachments: {
          name: 'solarModuleTypePlateAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceSolarPanelsOptionalAttachments',
          predecessor: 'servicePanelBottomViewAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceSolarPanelsOptionalAttachments: {
          name: 'serviceSolarPanelsOptionalAttachments',
          navigateTo: this.goToCard,
          successor: 'cableManagementC2State',
          predecessor: 'solarModuleTypePlateAttachments',
          opened: false,
          completed: this.completedState,
        },

        /** Cable Management */
        cableManagementC2State: {
          name: 'cableManagementC2State',
          navigateTo: this.goToCard,
          successor: 'serviceCableManagementAttachments',
          predecessor: 'serviceSolarPanelsOptionalAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceCableManagementAttachments: {
          name: 'serviceCableManagementAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceCableAlongFacadeAttachments',
          predecessor: 'cableManagementC2State',
          opened: false,
          completed: this.completedState,
        },
        serviceCableAlongFacadeAttachments: {
          name: 'serviceCableAlongFacadeAttachments',
          navigateTo: this.goToCard,
          successor: 'cableManagementOptional',
          predecessor: 'serviceCableManagementAttachments',
          opened: false,
          completed: this.completedState,
        },
        cableManagementOptional: {
          name: 'cableManagementOptional',
          navigateTo: this.goToCard,
          successor: 'hakLocationState',
          predecessor: 'serviceCableAlongFacadeAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** HAK */
        hakLocationState: {
          name: 'hakLocationState',
          navigateTo: this.goToCard,
          successor: 'hakLockedState',
          predecessor: 'cableManagementOptional',
          opened: false,
          completed: this.completedState,
        },
        hakLockedState: {
          name: 'hakLockedState',
          navigateTo: this.goToCard,
          successor: 'hakToZsDistance',
          predecessor: 'hakLocationState',
          opened: false,
          completed: this.completedState,
        },
        hakToZsDistance: {
          name: 'hakToZsDistance',
          navigateTo: this.goToCard,
          successor: 'hakAndZsSameRoomState',
          predecessor: 'hakLockedState',
          opened: false,
          completed: this.completedState,
        },
        hakAndZsSameRoomState: {
          name: 'hakAndZsSameRoomState',
          navigateTo: this.goToCard,
          successor: 'serviceHakAttachments',
          predecessor: 'hakToZsDistance',
          opened: false,
          completed: this.completedState,
        },
        serviceHakAttachments: {
          name: 'serviceHakAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceHakAndZsSameRoomAttachments',
          predecessor: 'hakAndZsSameRoomState',
          opened: false,
          completed: this.completedState,
        },
        serviceHakAndZsSameRoomAttachments: {
          name: 'serviceHakAndZsSameRoomAttachments',
          navigateTo: this.goToCard,
          successor: 'zsLocationState',
          predecessor: 'serviceHakAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Electric Meter */
        zsLocationState: {
          name: 'zsLocationState',
          navigateTo: this.goToCard,
          successor: 'zsWithCoverAttachments',
          predecessor: 'serviceHakAndZsSameRoomAttachments',
          opened: false,
          completed: this.completedState,
        },
        zsWithCoverAttachments: {
          name: 'zsWithCoverAttachments',
          navigateTo: this.goToCard,
          successor: 'zsWithoutCoverAttachments',
          predecessor: 'zsLocationState',
          opened: false,
          completed: this.completedState,
        },
        zsWithoutCoverAttachments: {
          name: 'zsWithoutCoverAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceZsSerialNumberAttachments',
          predecessor: 'zsWithCoverAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceZsSerialNumberAttachments: {
          name: 'serviceZsSerialNumberAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceZsPostCounterAreaAttachments',
          predecessor: 'zsWithoutCoverAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceZsPostCounterAreaAttachments: {
          name: 'serviceZsPostCounterAreaAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceZsPreCounterAreaAttachments',
          predecessor: 'serviceZsSerialNumberAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceZsPreCounterAreaAttachments: {
          name: 'serviceZsPreCounterAreaAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceZsDistributionAreaAttachments',
          predecessor: 'serviceZsPostCounterAreaAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceZsDistributionAreaAttachments: {
          name: 'serviceZsDistributionAreaAttachments',
          navigateTo: this.goToCard,
          successor: 'additionalSubDistributionState',
          predecessor: 'serviceZsPreCounterAreaAttachments',
          opened: false,
          completed: this.completedState,
        },
        additionalSubDistributionState: {
          name: 'additionalSubDistributionState',
          navigateTo: this.goToCard,
          successor: 'electricMeterCount',
          predecessor: 'serviceZsDistributionAreaAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Inverter */
        electricMeterCount: {
          name: 'electricMeterCount',
          navigateTo: this.goToCard,
          successor: 'electricMeterList',
          predecessor: 'additionalSubDistributionState',
          opened: false,
          completed: this.completedState,
        },
        electricMeterList: {
          name: 'electricMeterList',
          navigateTo: this.goToCard,
          successor: 'intermediateMeterRequestedState',
          predecessor: 'electricMeterCount',
          opened: false,
          completed: this.completedState,
        },
        intermediateMeterRequestedState: {
          name: 'intermediateMeterRequestedState',
          navigateTo: this.goToCard,
          successor: 'meterCombinationRequestedState',
          predecessor: 'electricMeterList',
          opened: false,
          completed: this.completedState,
        },
        meterCombinationRequestedState: {
          name: 'meterCombinationRequestedState',
          navigateTo: this.goToCard,
          successor: 'disassembledMetersOnSiteState',
          predecessor: 'intermediateMeterRequestedState',
          opened: false,
          completed: this.completedState,
        },
        disassembledMetersOnSiteState: {
          name: 'disassembledMetersOnSiteState',
          navigateTo: this.goToCard,
          successor: 'serviceDisassembledMetersAttachments',
          predecessor: 'meterCombinationRequestedState',
          opened: false,
          completed: this.completedState,
        },
        serviceDisassembledMetersAttachments: {
          name: 'serviceDisassembledMetersAttachments',
          navigateTo: this.goToCard,
          successor: 'meterCascadeRequestedState',
          predecessor: 'disassembledMetersOnSiteState',
          opened: false,
          completed: this.completedState,
        },
        meterCascadeRequestedState: {
          name: 'meterCascadeRequestedState',
          navigateTo: this.goToCard,
          successor: 'serviceMainElectricMeterAttachments',
          predecessor: 'serviceDisassembledMetersAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceMainElectricMeterAttachments: {
          name: 'serviceMainElectricMeterAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceSeconaryElectricMeterAttachments',
          predecessor: 'meterCascadeRequestedState',
          opened: false,
          completed: this.completedState,
        },
        serviceSeconaryElectricMeterAttachments: {
          name: 'serviceSeconaryElectricMeterAttachments',
          navigateTo: this.goToCard,
          successor: 'installationLocationWithInverterAndMateboxState',
          predecessor: 'serviceMainElectricMeterAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Installation Location */
        installationLocationWithInverterAndMateboxState: {
          name: 'installationLocationWithInverterAndMateboxState',
          navigateTo: this.goToCard,
          successor: 'serviceSecondaryInstallationLocationAttachments',
          predecessor: 'serviceSeconaryElectricMeterAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceSecondaryInstallationLocationAttachments: {
          name: 'serviceSecondaryInstallationLocationAttachments',
          navigateTo: this.goToCard,
          successor: 'cablePathDistance',
          predecessor: 'installationLocationWithInverterAndMateboxState',
          opened: false,
          completed: this.completedState,
        },
        cablePathDistance: {
          name: 'cablePathDistance',
          navigateTo: this.goToCard,
          successor: 'serviceCablePath360Attachments',
          predecessor: 'serviceSecondaryInstallationLocationAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceCablePath360Attachments: {
          name: 'serviceCablePath360Attachments',
          navigateTo: this.goToCard,
          successor: 'cableSelection',
          predecessor: 'cablePathDistance',
          opened: false,
          completed: this.completedState,
        },
        cableSelection: {
          name: 'cableSelection',
          navigateTo: this.goToCard,
          successor: 'serviceOpenMatebox',
          predecessor: 'serviceCablePath360Attachments',
          opened: false,
          completed: this.completedState,
        },
        serviceOpenMatebox: {
          name: 'serviceOpenMatebox',
          navigateTo: this.goToCard,
          successor: 'distanceMeasurement',
          predecessor: 'cableSelection',
          opened: false,
          completed: this.completedState,
        },
        distanceMeasurement: {
          name: 'distanceMeasurement',
          navigateTo: this.goToCard,
          successor: 'mtbxLabelSnAttachments',
          predecessor: 'serviceOpenMatebox',
          opened: false,
          completed: this.completedState,
        },
        /** Matebox Label */
        mtbxLabelSnAttachments: {
          name: 'mtbxLabelSnAttachments',
          navigateTo: this.goToCard,
          successor: 'invLabelSnAttachments',
          predecessor: 'distanceMeasurement',
          opened: false,
          completed: this.completedState,
        },
        /** Inverter Label */
        invLabelSnAttachments: {
          name: 'invLabelSnAttachments',
          navigateTo: this.goToCard,
          successor: 'batLabelSnAttachments',
          predecessor: 'mtbxLabelSnAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Battery Label */
        batLabelSnAttachments: {
          name: 'batLabelSnAttachments',
          navigateTo: this.goToCard,
          successor: 'equipotentialBusBarPresentState',
          predecessor: 'invLabelSnAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Earthing Unit */
        equipotentialBusBarPresentState: {
          name: 'equipotentialBusBarPresentState',
          navigateTo: this.goToCard,
          successor: 'secondaryEquipotentialBusBarPresentState',
          predecessor: 'batLabelSnAttachments',
          opened: false,
          completed: this.completedState,
        },

        secondaryEquipotentialBusBarPresentState: {
          name: 'secondaryEquipotentialBusBarPresentState',
          navigateTo: this.goToCard,
          successor: 'potentialGroundRodPresentState',
          predecessor: 'equipotentialBusBarPresentState',
          opened: false,
          completed: this.completedState,
        },
        potentialGroundRodPresentState: {
          name: 'potentialGroundRodPresentState',
          navigateTo: this.goToCard,
          successor: 'earthingSupply16mmState',
          predecessor: 'secondaryEquipotentialBusBarPresentState',
          opened: false,
          completed: this.completedState,
        },
        earthingSupply16mmState: {
          name: 'earthingSupply16mmState',
          navigateTo: this.goToCard,
          successor: 'serviceEarthingAttachments',
          predecessor: 'potentialGroundRodPresentState',
          opened: false,
          completed: this.completedState,
        },
        serviceEarthingAttachments: {
          name: 'serviceEarthingAttachments',
          navigateTo: this.goToCard,
          successor: 'internetConnectionTypeState',
          predecessor: 'earthingSupply16mmState',
          opened: false,
          completed: this.completedState,
        },
        /** InternetAccess */
        internetConnectionTypeState: {
          name: 'internetConnectionTypeState',
          navigateTo: this.goToCard,
          successor: 'lanConnectionPresentState',
          predecessor: 'serviceEarthingAttachments',
          opened: false,
          completed: this.completedState,
        },
        lanConnectionPresentState: {
          name: 'lanConnectionPresentState',
          navigateTo: this.goToCard,
          successor: 'qcellsWallboxContractState',
          predecessor: 'internetConnectionTypeState',
          opened: false,
          completed: this.completedState,
        },
        /** Wallbox */
        qcellsWallboxContractState: {
          name: 'qcellsWallboxContractState',
          navigateTo: this.goToCard,
          successor: 'serviceWallboxLocationAttachments',
          predecessor: 'lanConnectionPresentState',
          opened: false,
          completed: this.completedState,
        },
        serviceWallboxLocationAttachments: {
          name: 'serviceWallboxLocationAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceWallboxCablePathNotes',
          predecessor: 'qcellsWallboxContractState',
          opened: false,
          completed: this.completedState,
        },
        serviceWallboxCablePathNotes: {
          name: 'serviceWallboxCablePathNotes',
          navigateTo: this.goToCard,
          successor: 'serviceWallboxAttachments',
          predecessor: 'serviceWallboxLocationAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceWallboxAttachments: {
          name: 'serviceWallboxAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceWallboxAccessCardAttachments',
          predecessor: 'serviceWallboxCablePathNotes',
          opened: false,
          completed: this.completedState,
        },
        serviceWallboxAccessCardAttachments: {
          name: 'serviceWallboxAccessCardAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceFiLsWallboxAttachments',
          predecessor: 'serviceWallboxAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceFiLsWallboxAttachments: {
          name: 'serviceFiLsWallboxAttachments',
          navigateTo: this.goToCard,
          successor: 'briefingAndDocumentationState',
          predecessor: 'serviceWallboxAccessCardAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Misc */

        briefingAndDocumentationState: {
          name: 'briefingAndDocumentationState',
          navigateTo: this.goToCard,
          successor: 'serviceMiscOptionalAttachments',
          predecessor: 'serviceFiLsWallboxAttachments',
          opened: false,
          completed: this.completedState,
        },
        serviceMiscOptionalAttachments: {
          name: 'serviceMiscOptionalAttachments',
          navigateTo: this.goToCard,
          successor: 'serviceOptionalNotes',
          predecessor: 'briefingAndDocumentationState',
          opened: false,
          completed: this.completedState,
        },
        serviceOptionalNotes: {
          name: 'serviceOptionalNotes',
          navigateTo: this.goToCard,
          successor: null,
          predecessor: 'serviceMiscOptionalAttachments',
          opened: false,
          completed: this.completedState,
        },
      },
      selectedProjectAttachmentType: null,
      /** track Upload states for clearing dropzone preview */
    };
  },

  computed: {
    ...mapGetters(['getReportTemplateImages']),
    isFlatRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'FLAT_ROOF';
    },
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions([
      'uploadProjectAttachment',
      'saveReportSignature',
      'updateProject',
      'fetchEnumValues',
    ]),

    /**
     * save the project and open the target card
     * @todo DRY
     */
    async goToCard(target, update = true) {
      // console.log('next()', target);

      let card = target;
      this.toggle(this.cards[card]);

      // this.$nextTick(() => {
      //   this.scrollTo(card);
      // });

      if (update) {
        await this.updateProject(this.project);
      }
    },

    toggle(item) {
      item.opened = !item.opened;
    },

    /**
     * Emit a scroll event, the AccordionCard Component will resolve it
     */
    scrollTo(name) {
      this.$eventHub.$emit('scroll-to-accordion', name);
    },

    /**
     * Map a completed state to each field
     * @todo DRY
     */
    completedState(type, newValue = null) {
      // console.log('completedState():', type, newValue);
      this.project.serviceReport.completed = this.project.serviceReport.completed
        ? this.project.serviceReport.completed
        : [];

      const existingField = this.project.serviceReport.completed.find(
        (field) => field.type === type
      );

      if (!existingField) {
        const newField = { type: type, value: false };

        this.project.serviceReport.completed.push(newField);
        return newField.value;
      } else if (newValue !== null) {
        existingField.value = newValue;
      }

      return existingField.value;
    },

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },
  },
  async mounted() {
    await this.fetchEnumValues('BrickAndUkAssemblyType');
  },
};
</script>

<style lang="scss" scoped>
/** accordion */
.accordion button {
  text-align: left;
  padding: 0.8em 0.5em;
}
.accordion .card,
.accordion .card-header {
  border: 0;
  border-radius: 0em;
}
.accordion .btn.btn-block {
  border: 0;
  border-radius: 0em;
  font-weight: bold;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
