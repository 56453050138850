<template>
  <div>
    <AccordionCard :state="cards.polarityCheckState">
      <template v-slot:header>
        {{ $t('polarityCheckState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('polarityCheckState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.polarityCheckState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.polarityCheckNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.stringOpenCircuitVoltageState">
      <template v-slot:header>
        {{ $t('stringOpenCircuitVoltageState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('stringOpenCircuitVoltageState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.stringOpenCircuitVoltageState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.stringOpenCircuitVoltageNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.shortCircuitCurrentState">
      <template v-slot:header>
        {{ $t('shortCircuitCurrentState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('shortCircuitCurrentState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.shortCircuitCurrentState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.shortCircuitCurrentNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.insulationResistanceState">
      <template v-slot:header>
        {{ $t('insulationResistanceState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('insulationResistanceState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.insulationResistanceState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>

        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.insulationResistanceNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';

export default {
  name: 'SubstructureQualityReportAccordions',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  computed: {
    ...mapGetters(['getEnumValues']),
  },
};
</script>

<style lang="scss" scoped></style>
