<template>
  <div>
    <AccordionCard :state="cards.serviceStringPlanAttachments">
      <template v-slot:header>
        {{ $t('stringPlanAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('stringPlanAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('stringPlanAttachments_description') }}

          {{ $t('panelBottomViewAttachments_description') }} <br />
          {{ $t('templateAttachments') }}:
          <AttachmentGallery
            :attachments="getReportTemplateImages[47].templateAttachments"
            :deletable="false"
            :sortByName="false"
            :showFilename="false"
          ></AttachmentGallery>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceStringPlanAttachments"
            attachmentType="serviceStringPlanAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isstringPlanAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceStringPlanAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isstringPlanAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.stringPlanAvailableState">
      <template v-slot:header>
        {{ $t('stringPlanAvailableState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('stringPlanAvailableState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.stringPlanAvailableState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceAllPanelsVisibleAttachments">
      <template v-slot:header>
        {{ $t('allPanelsVisibleAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('allPanelsVisibleAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[37].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceAllPanelsVisibleAttachments"
            attachmentType="serviceAllPanelsVisibleAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isallPanelsVisibleAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceAllPanelsVisibleAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isallPanelsVisibleAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceClampAreaAttachments">
      <template v-slot:header>
        {{ $t('clampAreaAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('clampAreaAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[40].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceClampAreaAttachments"
            attachmentType="serviceClampAreaAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isclampAreaAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceClampAreaAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isclampAreaAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceTrayfDistanceAttachments">
      <template v-slot:header>
        {{ $t('trayfDistanceAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('trayfDistanceAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[30].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceTrayfDistanceAttachments"
            attachmentType="serviceTrayfDistanceAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="istrayfDistanceAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceTrayfDistanceAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="istrayfDistanceAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceRailOverlapAttachments">
      <template v-slot:header>
        {{ $t('serviceRailOverlapAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('serviceRailOverlapAttachments') }}</div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceRailOverlapAttachments"
            attachmentType="serviceRailOverlapAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceRailOverlapAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceRailOverlapAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isserviceRailOverlapAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.servicePanelBottomViewAttachments">
      <template v-slot:header>
        {{ $t('panelBottomViewAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('panelBottomViewAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('panelBottomViewAttachments_description') }} <br />
          {{ $t('templateAttachments') }}:
          <AttachmentGallery
            :attachments="getReportTemplateImages[33].templateAttachments"
            :deletable="false"
            :sortByName="false"
            :showFilename="false"
          ></AttachmentGallery>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.servicePanelBottomViewAttachments"
            attachmentType="servicePanelBottomViewAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="ispanelBottomViewAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="servicePanelBottomViewAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="ispanelBottomViewAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCardOptionalInput
      :card="cards.serviceSolarPanelsOptionalAttachments"
      :project="project"
      notesType="solarPanelNotes"
      :attachments="project.serviceSolarPanelsOptionalAttachments"
      attachmentType="serviceSolarPanelsOptionalAttachments"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';
import SelectButton from 'primevue/selectbutton';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    AccordionCardOptionalInput,
    SelectButton,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isstringPlanAttachmentsUploadComplete: false,
      isallPanelsVisibleAttachmentsUploadComplete: false,
      ispanelBottomViewAttachmentsUploadComplete: false,
      israilOverlapAttachmentsUploadComplete: false,
      isclampAreaAttachmentsUploadComplete: false,
      istrayfDistanceAttachmentsUploadComplete: false,
      isserviceRailOverlapAttachmentsUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues', 'getReportTemplateImages']),
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isstringPlanAttachmentsUploadComplete = true;
          this.isallPanelsVisibleAttachmentsUploadComplete = true;
          this.ispanelBottomViewAttachmentsUploadComplete = true;
          this.israilOverlapAttachmentsUploadComplete = true;
          this.isclampAreaAttachmentsUploadComplete = true;
          this.istrayfDistanceAttachmentsUploadComplete = true;
          this.isserviceRailOverlapAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
