<template>
  <div>
    <div class="accordion" role="tablist" v-if="project.measurement">
      <Roof :cards="cards" :project="project" />

      <h1>1. {{ $t('tileRoofState') }} ({{ $t('dcInside') }})</h1>
      <DcInside :cards="cards" :project="project" />

      <div v-if="isPitchedRoof">
        <h1>2. {{ $t('informative') }} ({{ $t('pitchedRoofDcExterior') }})</h1>
        <Informative :cards="cards" :project="project" />
        <h1>3. {{ $t('substructure') }} ({{ $t('pitchedRoofDcExterior') }})</h1>
        <Substructure :cards="cards" :project="project" />
        <h1>4. {{ $t('rafters') }} ({{ $t('pitchedRoofDcExterior') }})</h1>
        <Rafters :cards="cards" :project="project" />
        <h1>5. {{ $t('tileRoofState') }} ({{ $t('pitchedRoofDcExterior') }})</h1>
        <TileRoof :cards="cards" :project="project" />
        <h1>6. {{ $t('cables') }} ({{ $t('pitchedRoofDcExterior') }})</h1>
        <Cables :cards="cards" :project="project" />
      </div>

      <div v-if="isFlatRoof">
        <h1>2. {{ $t('informativeAndCommon') }} ({{ $t('flatRoof') }} {{ $t('DC') }})</h1>
        <InformativeFlatRoof :cards="cards" :project="project" />
        <h1>3. {{ $t('flatRoof') }} ({{ $t('flatRoof') }} {{ $t('DC') }})</h1>
        <FlatRoof :cards="cards" :project="project" />
        <h1>4. {{ $t('cables') }} ({{ $t('flatRoof') }} {{ $t('DC') }})</h1>
        <CablesFlatRoof :cards="cards" :project="project" />
      </div>

      <h1>
        <span v-if="isPitchedRoof">7</span>
        <span v-else>5</span>. {{ $t('informativeAndCommon') }} ({{ $t('AC') }})
      </h1>
      <InformativeAC :cards="cards" :project="project" />

      <h1>
        <span v-if="isPitchedRoof">8</span>
        <span v-else>6</span>. {{ $t('cables') }} ({{ $t('AC') }})
      </h1>
      <Wiring :cards="cards" :project="project" />

      <!-- <h1>
        <span v-if="isPitchedRoof">9</span>
        <span v-else>7</span>. {{ $t('measurements') }} ({{ $t('AC') }})
      </h1>
      <Measurements :cards="cards" :project="project" /> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Roof from './MobileMeasurement/Roof.vue';

import DcInside from './MobileQuality/DcInside.vue';
import Informative from './MobileQuality/Informative.vue';
import Substructure from './MobileQuality/Substructure.vue';
import Rafters from './MobileQuality/Rafters.vue';
import Cables from './MobileQuality/Cables.vue';
import TileRoof from './MobileQuality/TileRoof.vue';
import CablesFlatRoof from './MobileQuality/CablesFlatRoof.vue';
import FlatRoof from './MobileQuality/FlatRoof.vue';
import InformativeFlatRoof from './MobileQuality/InformativeFlatRoof.vue';
import InformativeAC from './MobileQuality/InformativeAC.vue';
import Wiring from './MobileQuality/Wiring.vue';
import Measurements from './MobileQuality/Measurements.vue';

export default {
  name: 'MobileProjectsReport',
  components: {
    Roof,
    DcInside,
    Informative,
    Substructure,
    Rafters,
    TileRoof,
    Cables,
    InformativeFlatRoof,
    FlatRoof,
    CablesFlatRoof,
    InformativeAC,
    Wiring,
    // Measurements,
  },

  props: {
    project: { type: Object },
  },
  data() {
    return {
      cards: {
        /** 1. Dachtyp */
        roofType: {
          name: 'roofType',
          opened: true,
          navigateTo: this.goToCard,
          successor: 'isRoofAccessibleFromInsideState',
          predecessor: null,
          completed: this.completedState,
        },
        /** st das Dach von der Innenseite prüfbar? */
        isRoofAccessibleFromInsideState: {
          name: 'isRoofAccessibleFromInsideState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'properSleeveUsedAtRoofPenetrationState',
          predecessor: 'roofType',
          completed: this.completedState,
        },
        /** Ordentlich verwendete Manschette bei Dachdurchführung */
        properSleeveUsedAtRoofPenetrationState: {
          name: 'properSleeveUsedAtRoofPenetrationState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'underlaySealedWellAtCableEntryState',
          predecessor: 'isRoofAccessibleFromInsideState',
          completed: this.completedState,
        },
        /**  Unterspannbahn gut abgedichtet bei Kabeleintritt */
        underlaySealedWellAtCableEntryState: {
          name: 'underlaySealedWellAtCableEntryState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'underlayDamagedOrWaterStainsState',
          predecessor: 'properSleeveUsedAtRoofPenetrationState',
          completed: this.completedState,
        },
        /** Unterspannbahn beschädigt oder Wasserschäden */
        underlayDamagedOrWaterStainsState: {
          name: 'underlayDamagedOrWaterStainsState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'labelingOfStringLinesState',
          predecessor: 'underlaySealedWellAtCableEntryState',
          completed: this.completedState,
        },
        /**  Beschriftung der String-Leitungen */
        labelingOfStringLinesState: {
          name: 'labelingOfStringLinesState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'pvStringCableCorrectCrossSectionState',
          predecessor: 'underlayDamagedOrWaterStainsState',
          completed: this.completedState,
        },
        /**  PV-Stringkabel korrekter Querschnitt */
        pvStringCableCorrectCrossSectionState: {
          name: 'pvStringCableCorrectCrossSectionState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'peCableCorrectlySizedState',
          predecessor: 'labelingOfStringLinesState',
          completed: this.completedState,
        },
        /** Funktions-Potentialausgleich zur PAS von  UK=6mm² */
        peCableCorrectlySizedState: {
          name: 'peCableCorrectlySizedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'cablesProperlySecuredState',
          predecessor: 'pvStringCableCorrectCrossSectionState',
          completed: this.completedState,
        },
        /**  Leitungen befestigt mit Schellen, Rohren, Kabelkanal? */
        cablesProperlySecuredState: {
          name: 'cablesProperlySecuredState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'crimpingProfessionallyDoneState',
          predecessor: 'peCableCorrectlySizedState',
          completed: this.completedState,
        },
        /**  Crimpung/Aderendhülsen  fachgerecht durchgeführt? */
        crimpingProfessionallyDoneState: {
          name: 'crimpingProfessionallyDoneState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'correctSelectionOfPvConnectorsOnStringCableState',
          predecessor: 'cablesProperlySecuredState',
          completed: this.completedState,
        },
        /** Richtige Auswahl der PV-Stecker am Stringkabel */
        correctSelectionOfPvConnectorsOnStringCableState: {
          name: 'correctSelectionOfPvConnectorsOnStringCableState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'allPlugConnectionsProfessionallyMadeState',
          predecessor: 'crimpingProfessionallyDoneState',
          completed: this.completedState,
        },
        /**  Alle Steckerverbindungen fachgerecht gesteckt */
        allPlugConnectionsProfessionallyMadeState: {
          name: 'allPlugConnectionsProfessionallyMadeState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'cablesNeatlyLaidInsideBuildingState',
          predecessor: 'correctSelectionOfPvConnectorsOnStringCableState',
          completed: this.completedState,
        },
        /**  Kabel sauber im Gebäudeinneren verlegt */
        cablesNeatlyLaidInsideBuildingState: {
          name: 'cablesNeatlyLaidInsideBuildingState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterState',
          predecessor: 'allPlugConnectionsProfessionallyMadeState',
          completed: this.completedState,
        },
        /**  falls Leitung in Gebäude länger als 10m eine 2. vorm WR verbaut */
        spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterState: {
          name: 'spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryState',
          predecessor: 'cablesNeatlyLaidInsideBuildingState',
          completed: this.completedState,
        },
        /**  Überspannungsbox richtig verdrahtet? */
        surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryState: {
          name: 'surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'isConnectorSealingIntactState',
          predecessor: 'spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterState',
          completed: this.completedState,
        },

        isConnectorSealingIntactState: {
          name: 'isConnectorSealingIntactState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'isSurgeProtectionBoxPresenState',
          predecessor: 'surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryState',
          completed: this.completedState,
        },
        isSurgeProtectionBoxPresenState: {
          name: 'isSurgeProtectionBoxPresenState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'isSurgeProtectionAtHouseEntryState',
          predecessor: 'isConnectorSealingIntactState',
          completed: this.completedState,
        },
        isSurgeProtectionAtHouseEntryState: {
          name: 'isSurgeProtectionAtHouseEntryState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'documentationProjectReportAvailableState',
          predecessor: 'isSurgeProtectionBoxPresenState',
          completed: this.completedState,
        },
        documentationProjectReportAvailableState: {
          name: 'documentationProjectReportAvailableState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'specialCustomerRequestsDocumentedState',
          predecessor: 'isSurgeProtectionAtHouseEntryState',
          completed: this.completedState,
        },
        /** Gibt es Sonderwünsche des Kunden und wurden Dokumentiert? */
        specialCustomerRequestsDocumentedState: {
          name: 'specialCustomerRequestsDocumentedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'tileTypeAndWidthDocumentedState',
          predecessor: 'documentationProjectReportAvailableState',
          completed: this.completedState,
        },
        /** Ziegelart und Ziegeldeckbreite dokumentiert? */
        tileTypeAndWidthDocumentedState: {
          name: 'tileTypeAndWidthDocumentedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'replacementTileInstalledState',
          predecessor: 'specialCustomerRequestsDocumentedState',
          completed: this.completedState,
        },
        /** Ziegelersatzplatte verbaut? */
        replacementTileInstalledState: {
          name: 'replacementTileInstalledState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'lightningProtectionPresentState',
          predecessor: 'tileTypeAndWidthDocumentedState',
          completed: this.completedState,
        },
        /** Blitzschutz vorhanden? */
        lightningProtectionPresentState: {
          name: 'lightningProtectionPresentState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'substructureTensionFreeCheckedState',
          predecessor: 'replacementTileInstalledState',
          completed: this.completedState,
        },
        /** Schiene ordnungsgemäß montiert */
        substructureTensionFreeCheckedState: {
          name: 'substructureTensionFreeCheckedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'substructureInstalledAsPerReportState',
          predecessor: 'lightningProtectionPresentState',
          completed: this.completedState,
        },
        /** UK nach Projektbericht verbaut? */
        substructureInstalledAsPerReportState: {
          name: 'substructureInstalledAsPerReportState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'eachRailSecuredWithMinTwoHooksState',
          predecessor: 'substructureTensionFreeCheckedState',
          completed: this.completedState,
        },
        /** Jede Schiene ist mit mind. 2 Dachhaken befestigt? */
        eachRailSecuredWithMinTwoHooksState: {
          name: 'eachRailSecuredWithMinTwoHooksState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'distanceBetweenHooksMoreThan140cmState',
          predecessor: 'substructureInstalledAsPerReportState',
          completed: this.completedState,
        },
        /** Abstand zwischen Haken kleiner als 140cm? */
        distanceBetweenHooksMoreThan140cmState: {
          name: 'distanceBetweenHooksMoreThan140cmState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'correctRoofHooksUsedState',
          predecessor: 'eachRailSecuredWithMinTwoHooksState',
          completed: this.completedState,
        },
        /** Dachhaken ordnungsgemäß montiert */
        correctRoofHooksUsedState: {
          name: 'correctRoofHooksUsedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'distanceRoofHookToTile35mmState',
          predecessor: 'distanceBetweenHooksMoreThan140cmState',
          completed: this.completedState,
        },
        /** Abstand Dachhaken zum darunter liegenden Ziegel von 3-5mm? */
        distanceRoofHookToTile35mmState: {
          name: 'distanceRoofHookToTile35mmState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'minScrewDistanceToRafterEdge24mmState',
          predecessor: 'correctRoofHooksUsedState',
          completed: this.completedState,
        },
        /** Mindestabstand Schrauben zur Sparrenkante kleiner als 24mm? */
        minScrewDistanceToRafterEdge24mmState: {
          name: 'minScrewDistanceToRafterEdge24mmState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'roofHookProperlyFixedToRafterState',
          predecessor: 'distanceRoofHookToTile35mmState',
          completed: this.completedState,
        },
        /** Dachhaken richtig auf Sprarren befestigt? */
        roofHookProperlyFixedToRafterState: {
          name: 'roofHookProperlyFixedToRafterState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'overhangObservedState',
          predecessor: 'minScrewDistanceToRafterEdge24mmState',
          completed: this.completedState,
        },
        /** Kragarm eingehalten? (TP37 - 35cm; TP45 & TP65 - 45cm) */
        overhangObservedState: {
          name: 'overhangObservedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'moduleFieldProperlyGroundedState',
          predecessor: 'roofHookProperlyFixedToRafterState',
          completed: this.completedState,
        },
        /** Modulfeld fachgerecht untereinander geerdet? */
        moduleFieldProperlyGroundedState: {
          name: 'moduleFieldProperlyGroundedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'approvedAuxiliaryRafterUsedState',
          predecessor: 'overhangObservedState',
          completed: this.completedState,
        },
        /** Freigegebener Hilfssparren verwendet (Statikgutachen)? */
        approvedAuxiliaryRafterUsedState: {
          name: 'approvedAuxiliaryRafterUsedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'rafterWidthMeasuredAndComparedToDocsState',
          predecessor: 'moduleFieldProperlyGroundedState',
          completed: this.completedState,
        },
        /** Sparrenbreite mind. 60mm   (laut Dokumentation vergleichen) */
        rafterWidthMeasuredAndComparedToDocsState: {
          name: 'rafterWidthMeasuredAndComparedToDocsState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'noOpenOrDamagedTilesState',
          predecessor: 'approvedAuxiliaryRafterUsedState',
          completed: this.completedState,
        },
        /** Keine offene oder beschädigte Ziegel? */
        noOpenOrDamagedTilesState: {
          name: 'noOpenOrDamagedTilesState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'noShiftedOrIncorrectlyLaidTilesState',
          predecessor: 'rafterWidthMeasuredAndComparedToDocsState',
          completed: this.completedState,
        },
        /** Modulrahmen unbeschädigt? */
        noShiftedOrIncorrectlyLaidTilesState: {
          name: 'noShiftedOrIncorrectlyLaidTilesState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'tilesProperlyCleanedState',
          predecessor: 'noOpenOrDamagedTilesState',
          completed: this.completedState,
        },
        /** Modulglas unbeschädigt */
        tilesProperlyCleanedState: {
          name: 'tilesProperlyCleanedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'correctPanHeadScrewsUsedState',
          predecessor: 'noShiftedOrIncorrectlyLaidTilesState',
          completed: this.completedState,
        },
        /** Richtige Tellerkopfschrauben verwendet? */
        correctPanHeadScrewsUsedState: {
          name: 'correctPanHeadScrewsUsedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'minRailOverhangAfterEndClamp20mmState',
          predecessor: 'tilesProperlyCleanedState',
          completed: this.completedState,
        },
        /** Mindestüberstand der Schiene nach Endklemme größer als 20mm? (ohne Endkappe messen) */
        minRailOverhangAfterEndClamp20mmState: {
          name: 'minRailOverhangAfterEndClamp20mmState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'distancesToRidgeEavesNotLessThan20cmState',
          predecessor: 'correctPanHeadScrewsUsedState',
          completed: this.completedState,
        },
        /** Abstände zu First/Traufe nicht kleiner als 20cm? */
        distancesToRidgeEavesNotLessThan20cmState: {
          name: 'distancesToRidgeEavesNotLessThan20cmState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'moduleClampAreasObservedState',
          predecessor: 'minRailOverhangAfterEndClamp20mmState',
          completed: this.completedState,
        },
        /** Modulklemmbereiche eingehalten? */
        moduleClampAreasObservedState: {
          name: 'moduleClampAreasObservedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'modulesNoDamagesDirtShadingState',
          predecessor: 'distancesToRidgeEavesNotLessThan20cmState',
          completed: this.completedState,
        },
        /** Module keine Beschädigungen */
        modulesNoDamagesDirtShadingState: {
          name: 'modulesNoDamagesDirtShadingState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'moduleClampsProperlyTightenedState',
          predecessor: 'moduleClampAreasObservedState',
          completed: this.completedState,
        },
        /** Modulklemmen fest? */
        moduleClampsProperlyTightenedState: {
          name: 'moduleClampsProperlyTightenedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'thermalSeparationAfter12mModuleFieldState',
          predecessor: 'modulesNoDamagesDirtShadingState',
          completed: this.completedState,
        },
        /** Thermische Trennung nach 12m Modulfeld? */
        thermalSeparationAfter12mModuleFieldState: {
          name: 'thermalSeparationAfter12mModuleFieldState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'breakthroughProperlySealedState',
          predecessor: 'moduleClampsProperlyTightenedState',
          completed: this.completedState,
        },
        /** Durchbruch fachgerecht abgedichtet? */
        breakthroughProperlySealedState: {
          name: 'breakthroughProperlySealedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'distanceLightningProtectionToConductivePartsLessThan05mState',
          predecessor: 'thermalSeparationAfter12mModuleFieldState',
          completed: this.completedState,
        },
        /** Backsheet unbeschädigt? */
        distanceLightningProtectionToConductivePartsLessThan05mState: {
          name: 'distanceLightningProtectionToConductivePartsLessThan05mState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'gutterUndamagedState',
          predecessor: 'breakthroughProperlySealedState',
          completed: this.completedState,
        },
        /** Regenrinne unbeschädigt? */
        gutterUndamagedState: {
          name: 'gutterUndamagedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'notBuiltOverRoofOverhangState',
          predecessor: 'distanceLightningProtectionToConductivePartsLessThan05mState',
          completed: this.completedState,
        },
        /** Dachüberstand nicht bebaut? */
        notBuiltOverRoofOverhangState: {
          name: 'notBuiltOverRoofOverhangState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'cableProperlyLaidInChimneyState',
          predecessor: 'gutterUndamagedState',
          completed: this.completedState,
        },
        /** Kabel im Kamin fachgerecht verlegt? */
        cableProperlyLaidInChimneyState: {
          name: 'cableProperlyLaidInChimneyState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'distanceToDisturbingSurfaces10cmObservedState',
          predecessor: 'notBuiltOverRoofOverhangState',
          completed: this.completedState,
        },
        /** Abstand zu Störflachen von 10cm eingehalten? */
        distanceToDisturbingSurfaces10cmObservedState: {
          name: 'distanceToDisturbingSurfaces10cmObservedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'cableEntryWithReplacementPlateAndFlexedTileState',
          predecessor: 'cableProperlyLaidInChimneyState',
          completed: this.completedState,
        },
        /** Flugsparren? */
        cableEntryWithReplacementPlateAndFlexedTileState: {
          name: 'cableEntryWithReplacementPlateAndFlexedTileState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'cablesDoNotRestOnTilesState',
          predecessor: 'distanceToDisturbingSurfaces10cmObservedState',
          completed: this.completedState,
        },
        /** Leitungen ordnungsgemäß verlegt? (nicht auf Ziegeln) */
        cablesDoNotRestOnTilesState: {
          name: 'cablesDoNotRestOnTilesState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'cablesLaidProtectedUnderTilesState',
          predecessor: 'cableEntryWithReplacementPlateAndFlexedTileState',
          completed: this.completedState,
        },
        /** Kabelführung unter Ziegel im Schutzrohr verlegt? */
        cablesLaidProtectedUnderTilesState: {
          name: 'cablesLaidProtectedUnderTilesState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'cableEntryOnRoofProperlyDoneState',
          predecessor: 'cablesDoNotRestOnTilesState',
          completed: this.completedState,
        },
        /** Kabelführung an dem Dachhaken befestigt  und im Schutzrohr verlegt? */
        cableEntryOnRoofProperlyDoneState: {
          name: 'cableEntryOnRoofProperlyDoneState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'cableLaidThroughChimneyState',
          predecessor: 'cablesLaidProtectedUnderTilesState',
          completed: this.completedState,
        },
        /** Kabel durch Kamin verlegt? */
        cableLaidThroughChimneyState: {
          name: 'cableLaidThroughChimneyState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'cableLaidInProtectiveSleeveAgainstUvRadiationState',
          predecessor: 'cableEntryOnRoofProperlyDoneState',
          completed: this.completedState,
        },
        /** Kabel im Schutzrohr vor UV-Strahlung verlegt? */
        cableLaidInProtectiveSleeveAgainstUvRadiationState: {
          name: 'cableLaidInProtectiveSleeveAgainstUvRadiationState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'isModuleNotShaded2State',
          predecessor: 'cableLaidThroughChimneyState',
          completed: this.completedState,
        },

        isModuleNotShaded2State: {
          name: 'isModuleNotShaded2State',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'areModuleClampsNotTwistedState',
          predecessor: 'cableLaidInProtectiveSleeveAgainstUvRadiationState',
          completed: this.completedState,
        },
        areModuleClampsNotTwistedState: {
          name: 'areModuleClampsNotTwistedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'isChimneySweeperApprovalGiven2State',
          predecessor: 'isModuleNotShaded2State',
          completed: this.completedState,
        },
        isChimneySweeperApprovalGiven2State: {
          name: 'isChimneySweeperApprovalGiven2State',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'peCableProperlySecuredState',
          predecessor: 'areModuleClampsNotTwistedState',
          completed: this.completedState,
        },

        /** PE Leitung ordentlich befestigt? */
        peCableProperlySecuredState: {
          name: 'peCableProperlySecuredState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'pvSystemLabeledInOrAtDistributionBoardState',
          predecessor: 'isChimneySweeperApprovalGiven2State',
          completed: this.completedState,
        },

        /** Dokumentation/Projektbericht vorhanden? */
        documentationProjectReportPresentState: {
          name: 'documentationProjectReportPresentState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'customerSpecialRequestsConsideredState',
          predecessor: 'peCableProperlySecuredState',
          completed: this.completedState,
        },
        /** Gibt es Sonderwünsche des Kunden und wurden Dokumentiert? */
        customerSpecialRequestsConsideredState: {
          name: 'customerSpecialRequestsConsideredState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'flatRooflightningProtectionPresentState',
          predecessor: 'documentationProjectReportPresentState',
          completed: this.completedState,
        },
        /** Blitzschutz vorhanden? */
        flatRooflightningProtectionPresentState: {
          name: 'flatRooflightningProtectionPresentState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'isLightningProtectionDistanceToConductivePartsOfPVSystemAtLeast0_5mState',
          predecessor: 'customerSpecialRequestsConsideredState',
          completed: this.completedState,
        },
        isLightningProtectionDistanceToConductivePartsOfPVSystemAtLeast0_5mState: {
          name: 'isLightningProtectionDistanceToConductivePartsOfPVSystemAtLeast0_5mState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'properGroundingFlatRoofSystemState',
          predecessor: 'flatRooflightningProtectionPresentState',
          completed: this.completedState,
        },
        /** Fachgerechte Erdung des Flachdachssystems? */
        properGroundingFlatRoofSystemState: {
          name: 'properGroundingFlatRoofSystemState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'ballastingAccordingToProjectReportState',
          predecessor: 'isLightningProtectionDistanceToConductivePartsOfPVSystemAtLeast0_5mState',
          completed: this.completedState,
        },
        /** Ballastierung laut Projektbericht angeordnet und gegen Verrutschen gesichert? */
        ballastingAccordingToProjectReportState: {
          name: 'ballastingAccordingToProjectReportState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'minimumModuleBearingAreaMaintainedState',
          predecessor: 'properGroundingFlatRoofSystemState',
          completed: this.completedState,
        },
        /** Mindestauflagefläche der Module auf Tower & Base von 20mm eingehalten? */
        minimumModuleBearingAreaMaintainedState: {
          name: 'minimumModuleBearingAreaMaintainedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'qFlatProperlyConnectedState',
          predecessor: 'ballastingAccordingToProjectReportState',
          completed: this.completedState,
        },
        /** Wurden Q.Flat fachgerecht ineinander gesteckt und befestigt? */
        qFlatProperlyConnectedState: {
          name: 'qFlatProperlyConnectedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'distanceToDisturbingSurfacesMaintainedState',
          predecessor: 'minimumModuleBearingAreaMaintainedState',
          completed: this.completedState,
        },
        /** Abstand zu Störflächen von 10cm eingehalten? */
        distanceToDisturbingSurfacesMaintainedState: {
          name: 'distanceToDisturbingSurfacesMaintainedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'flatRoofmoduleClampsProperlyTightenedState',
          predecessor: 'qFlatProperlyConnectedState',
          completed: this.completedState,
        },
        /** Modulklemmen fest? */
        flatRoofmoduleClampsProperlyTightenedState: {
          name: 'flatRoofmoduleClampsProperlyTightenedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'modulesNoDamageDirtShadingState',
          predecessor: 'distanceToDisturbingSurfacesMaintainedState',
          completed: this.completedState,
        },
        /** Module keine Beschädigungen */
        modulesNoDamageDirtShadingState: {
          name: 'modulesNoDamageDirtShadingState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'typeOfCableProtectionOutdoorsState',
          predecessor: 'flatRoofmoduleClampsProperlyTightenedState',
          completed: this.completedState,
        },
        /** Module Verschmutzungen? */
        typeOfCableProtectionOutdoorsState: {
          name: 'typeOfCableProtectionOutdoorsState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'correctSelectionOfPvConnectorsState',
          predecessor: 'modulesNoDamageDirtShadingState',
          completed: this.completedState,
        },
        /** Richtige Auswahl der PV-Stecker am Strangkabel? */
        correctSelectionOfPvConnectorsState: {
          name: 'correctSelectionOfPvConnectorsState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'cablesDoNotRestOnRoofState',
          predecessor: 'typeOfCableProtectionOutdoorsState',
          completed: this.completedState,
        },
        /** Modulklemmen nicht verdreht? */
        cablesDoNotRestOnRoofState: {
          name: 'cablesDoNotRestOnRoofState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'isModuleFrameUndamagedState',
          predecessor: 'correctSelectionOfPvConnectorsState',
          completed: this.completedState,
        },
        isModuleFrameUndamagedState: {
          name: 'isModuleFrameUndamagedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'isModuleGlassUndamagedState',
          predecessor: 'cablesDoNotRestOnRoofState',
          completed: this.completedState,
        },
        isModuleGlassUndamagedState: {
          name: 'isModuleGlassUndamagedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'isBacksheetUndamagedState',
          predecessor: 'isModuleFrameUndamagedState',
          completed: this.completedState,
        },
        isBacksheetUndamagedState: {
          name: 'isBacksheetUndamagedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'isModuleNotShadedState',
          predecessor: 'isModuleGlassUndamagedState',
          completed: this.completedState,
        },
        isModuleNotShadedState: {
          name: 'isModuleNotShadedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'cableEntryDoneProperlyState',
          predecessor: 'isBacksheetUndamagedState',
          completed: this.completedState,
        },
        /** Kabelführung an dem Dachhaken befestigt  und im Schutzrohr verlegt? */
        cableEntryDoneProperlyState: {
          name: 'cableEntryDoneProperlyState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'cablesProtectedFromUvState',
          predecessor: 'isModuleNotShadedState',
          completed: this.completedState,
        },
        /** Kabel im Schutzrohr vor UV-Strahlung verlegt? */
        cablesProtectedFromUvState: {
          name: 'cablesProtectedFromUvState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'pvStringCableProperlySizedState',
          predecessor: 'cableEntryDoneProperlyState',
          completed: this.completedState,
        },
        /** PV Strangkabel 6mm² */
        pvStringCableProperlySizedState: {
          name: 'pvStringCableProperlySizedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'stringCableLabelingState',
          predecessor: 'cablesProtectedFromUvState',
          completed: this.completedState,
        },
        /** Beschriftung der Strangleitungen? */
        stringCableLabelingState: {
          name: 'stringCableLabelingState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'peCableProperlySizedState',
          predecessor: 'pvStringCableProperlySizedState',
          completed: this.completedState,
        },
        /** Funktions-Potentialausgleich zur PAS von  UK=6mm²  */
        peCableProperlySizedState: {
          name: 'peCableProperlySizedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'flatRoofcablesProperlySecuredState',
          predecessor: 'stringCableLabelingState',
          completed: this.completedState,
        },
        /** Leitungen befestigt mit Schellen, Rohren, Kabelkanal? */
        flatRoofcablesProperlySecuredState: {
          name: 'flatRoofcablesProperlySecuredState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'isConnectorProperlyPluggedState',
          predecessor: 'peCableProperlySizedState',
          completed: this.completedState,
        },
        isConnectorProperlyPluggedState: {
          name: 'isConnectorProperlyPluggedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'isConnectorSealingIntact2State',
          predecessor: 'flatRoofcablesProperlySecuredState',
          completed: this.completedState,
        },
        isConnectorSealingIntact2State: {
          name: 'isConnectorSealingIntact2State',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'isCableRoutedThroughChimneyState',
          predecessor: 'isConnectorProperlyPluggedState',
          completed: this.completedState,
        },
        isCableRoutedThroughChimneyState: {
          name: 'isCableRoutedThroughChimneyState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'isChimneySweeperApprovalGivenState',
          predecessor: 'isConnectorSealingIntact2State',
          completed: this.completedState,
        },
        isChimneySweeperApprovalGivenState: {
          name: 'isChimneySweeperApprovalGivenState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'isWallPassageProperlySealedState',
          predecessor: 'isCableRoutedThroughChimneyState',
          completed: this.completedState,
        },
        isWallPassageProperlySealedState: {
          name: 'isWallPassageProperlySealedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'crimpingProperlyDoneState',
          predecessor: 'isChimneySweeperApprovalGivenState',
          completed: this.completedState,
        },
        /** Crimpung/Aderendhülsen  fachgerecht durchgeführt? */
        crimpingProperlyDoneState: {
          name: 'crimpingProperlyDoneState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'pvSystemLabeledInOrAtDistributionBoardState',
          predecessor: 'isWallPassageProperlySealedState',
          completed: this.completedState,
        },
        /** Ist außen am Schaltschrank die PV Anlage gekennzeichnet? */
        pvSystemLabeledInOrAtDistributionBoardState: {
          name: 'pvSystemLabeledInOrAtDistributionBoardState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'testReportAvailableWithAllMeasurementsState',
          predecessor: 'crimpingProperlyDoneState',
          completed: this.completedState,
        },

        /** Ist das Prüfprotokoll mit allen Messungen vorhanden? */
        testReportAvailableWithAllMeasurementsState: {
          name: 'testReportAvailableWithAllMeasurementsState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'rcdTypeB300maAvailableInMeterAreaState',
          predecessor: 'pvSystemLabeledInOrAtDistributionBoardState',
          completed: this.completedState,
        },

        /** im Nachzählerbereich RCD Typ B 300mA vorhanden? */
        rcdTypeB300maAvailableInMeterAreaState: {
          name: 'rcdTypeB300maAvailableInMeterAreaState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'stringPlanFiledInMeterCabinetState',
          predecessor: 'testReportAvailableWithAllMeasurementsState',
          completed: this.completedState,
        },

        /** Stringplan im Zählerschrank hinterlegt? */
        stringPlanFiledInMeterCabinetState: {
          name: 'stringPlanFiledInMeterCabinetState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'safetyDistancesMaintainedState',
          predecessor: 'rcdTypeB300maAvailableInMeterAreaState',
          completed: this.completedState,
        },

        /** Wurden die Sicherheitsabstände eingehalten? */
        safetyDistancesMaintainedState: {
          name: 'safetyDistancesMaintainedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'correctLocationForSystemState',
          predecessor: 'stringPlanFiledInMeterCabinetState',
          completed: this.completedState,
        },

        /** Richtiger Aufstellort des Systems? (nicht auf Holz, Witterung…) */
        correctLocationForSystemState: {
          name: 'correctLocationForSystemState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'correctLocationForWallboxState',
          predecessor: 'safetyDistancesMaintainedState',
          completed: this.completedState,
        },

        /** Wallbox richtiger Aufstellort? */
        correctLocationForWallboxState: {
          name: 'correctLocationForWallboxState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'customerRequestsDocumentedState',
          predecessor: 'correctLocationForSystemState',
          completed: this.completedState,
        },

        /** Falls Kunde etwas anders haben will (z.B. Plastik Kabelabdeckungen anstatt Rohrverlegung) dann muss sowas schriftlich dokumentiert werden */
        customerRequestsDocumentedState: {
          name: 'customerRequestsDocumentedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'slsInstalledState',
          predecessor: 'correctLocationForWallboxState',
          completed: this.completedState,
        },

        /** SLS verbaut? */
        slsInstalledState: {
          name: 'slsInstalledState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'componentsDamagedState',
          predecessor: 'customerRequestsDocumentedState',
          completed: this.completedState,
        },

        /** Sind Komponenten unbeschädigt? */
        componentsDamagedState: {
          name: 'componentsDamagedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'crimpingDoneProperlyState',
          predecessor: 'slsInstalledState',
          completed: this.completedState,
        },

        /** Wurde fachgerecht gecrimpt? (Aderendhülsen, Kabelschuhe) */
        crimpingDoneProperlyState: {
          name: 'crimpingDoneProperlyState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'ussWiredProperlyInCabinetState',
          predecessor: 'componentsDamagedState',
          completed: this.completedState,
        },

        /** ÜSS im ZS fachgerecht verdrahtet? */
        ussWiredProperlyInCabinetState: {
          name: 'ussWiredProperlyInCabinetState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'ussBoxWiredProperlyState',
          predecessor: 'crimpingDoneProperlyState',
          completed: this.completedState,
        },

        /** ÜSS-Box fachgerecht verdrahtet? */
        ussBoxWiredProperlyState: {
          name: 'ussBoxWiredProperlyState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'bendingRadiiMaintainedState',
          predecessor: 'ussWiredProperlyInCabinetState',
          completed: this.completedState,
        },

        /** Wurden die Biegeradien eingehalten? */
        bendingRadiiMaintainedState: {
          name: 'bendingRadiiMaintainedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'correctWiringOfMateboxState',
          predecessor: 'ussBoxWiredProperlyState',
          completed: this.completedState,
        },

        /** Richtige Verkabelung der Matebox? 10mm2 GRID LOAD zum ZS */
        correctWiringOfMateboxState: {
          name: 'correctWiringOfMateboxState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'correctWiringOfBatteryState',
          predecessor: 'bendingRadiiMaintainedState',
          completed: this.completedState,
        },

        /** Richtige Verdrahtung der Batterie? */
        correctWiringOfBatteryState: {
          name: 'correctWiringOfBatteryState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'allComponentsProperlyGroundedState',
          predecessor: 'correctWiringOfMateboxState',
          completed: this.completedState,
        },

        /** Sind alle Komponenten untereinander geerdet? */
        allComponentsProperlyGroundedState: {
          name: 'allComponentsProperlyGroundedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'correctColorCodingAndLabelingState',
          predecessor: 'correctWiringOfBatteryState',
          completed: this.completedState,
        },

        /** Richtige Farbauswahl / Beschriftung bei Leitungen? (falls falsche Farbe dann muss Beschriftung da sein!) */
        correctColorCodingAndLabelingState: {
          name: 'correctColorCodingAndLabelingState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'correctWiringOfPvConnectorsState',
          predecessor: 'allComponentsProperlyGroundedState',
          completed: this.completedState,
        },

        /** Richtige Verdrahtung der PV-Stecker von Matebox zum Wechselrichter */
        correctWiringOfPvConnectorsState: {
          name: 'correctWiringOfPvConnectorsState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'cableDuctProperlySizedAndCutState',
          predecessor: 'correctColorCodingAndLabelingState',
          completed: this.completedState,
        },

        /** Kabelkanal richtige Dimension und sauberes zuschneiden */
        cableDuctProperlySizedAndCutState: {
          name: 'cableDuctProperlySizedAndCutState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'nhHakSelectiveToSlsState',
          predecessor: 'correctWiringOfPvConnectorsState',
          completed: this.completedState,
        },

        /** NH HAK selektiv zu SLS */
        nhHakSelectiveToSlsState: {
          name: 'nhHakSelectiveToSlsState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'checkNetworkTypeState',
          predecessor: 'cableDuctProperlySizedAndCutState',
          completed: this.completedState,
        },

        /** Prüfen der Netzart */
        checkNetworkTypeState: {
          name: 'checkNetworkTypeState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'earthRodConnectedToHpasState',
          predecessor: 'nhHakSelectiveToSlsState',
          completed: this.completedState,
        },

        /** Banderder/Staberder an HPAS angeschlossen? */
        earthRodConnectedToHpasState: {
          name: 'earthRodConnectedToHpasState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'connectionBetweenHpasAndPasProperlyMadeState',
          predecessor: 'checkNetworkTypeState',
          completed: this.completedState,
        },

        /** Verbindung HPAS/PAS fachgerecht hergestellt? */
        connectionBetweenHpasAndPasProperlyMadeState: {
          name: 'connectionBetweenHpasAndPasProperlyMadeState',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'insulationProperlyStrippedState',
          predecessor: 'earthRodConnectedToHpasState',
          completed: this.completedState,
        },
        /** Isolierung weit genug abisoliert */
        insulationProperlyStrippedState: {
          name: 'insulationProperlyStrippedState',
          opened: false,
          navigateTo: this.goToCard,
          successor: null,
          predecessor: 'connectionBetweenHpasAndPasProperlyMadeState',
          completed: this.completedState,
        },

        // /** Polaritätsprüfung */
        // polarityCheckState: {
        //   name: 'polarityCheckState',
        //   opened: false,
        //   navigateTo: this.goToCard,
        //   successor: 'stringOpenCircuitVoltageState',
        //   predecessor: 'insulationProperlyStrippedState',
        //   completed: this.completedState,
        // },

        // /** Leerlaufspannung des Strangs */
        // stringOpenCircuitVoltageState: {
        //   name: 'stringOpenCircuitVoltageState',
        //   opened: false,
        //   navigateTo: this.goToCard,
        //   successor: 'shortCircuitCurrentState',
        //   predecessor: 'polarityCheckState',
        //   completed: this.completedState,
        // },

        // /** Kurzschlussstrom */
        // shortCircuitCurrentState: {
        //   name: 'shortCircuitCurrentState',
        //   opened: false,
        //   navigateTo: this.goToCard,
        //   successor: 'insulationResistanceState',
        //   predecessor: 'stringOpenCircuitVoltageState',
        //   completed: this.completedState,
        // },

        // /** Isolationswiderstand */
        // insulationResistanceState: {
        //   name: 'insulationResistanceState',
        //   opened: false,
        //   navigateTo: this.goToCard,
        //   successor: null,
        //   predecessor: 'shortCircuitCurrentState',
        //   completed: this.completedState,
        // },
      },
      selectedProjectStateType: null,
      /** track Upload states for clearing dropzone preview */
    };
  },
  computed: {
    ...mapGetters(['getReportTemplateImages']),
    isFlatRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'FLAT_ROOF';
    },
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions([
      'uploadProjectAttachment',
      'saveReportSignature',
      'updateProject',
      'fetchEnumValues',
    ]),

    /**
     * save the project and open the target card
     * @todo DRY
     */
    async goToCard(target, update = true) {
      let card = target;
      // console.log('target: ', target);
      if (target === 'documentationProjectReportAvailableState') {
        card = this.isFlatRoof
          ? 'documentationProjectReportPresentState'
          : 'documentationProjectReportAvailableState';
      }
      if (target === 'crimpingProperlyDoneState') {
        card = this.isPitchedRoof ? 'peCableProperlySecuredState' : 'crimpingProperlyDoneState';
      }

      if (target === 'peCableProperlySecuredState') {
        card = this.isFlatRoof
          ? 'surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryState'
          : 'peCableProperlySecuredState';
      }

      this.toggle(this.cards[card]);

      this.$nextTick(() => {
        this.scrollTo(card);
      });

      if (update) {
        await this.updateProject(this.project);
      }
    },
    toggle(item) {
      item.opened = !item.opened;
    },

    /**
     * Emit a scroll event, the AccordionCard Component will resolve it
     */
    scrollTo(name) {
      this.$eventHub.$emit('scroll-to-accordion', name);
    },

    /**
     * Map a completed state to each field
     * @todo DRY
     */
    completedState(type, newValue = null) {
      // console.log('completedState():', type, newValue);
      this.project.enpalReport.completed = this.project.enpalReport.completed
        ? this.project.enpalReport.completed
        : [];

      const existingField = this.project.enpalReport.completed.find((field) => field.type === type);

      if (!existingField) {
        const newField = { type: type, value: false };

        this.project.enpalReport.completed.push(newField);
        return newField.value;
      } else if (newValue !== null) {
        existingField.value = newValue;
      }

      return existingField.value;
    },

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },
  },
  async mounted() {
    await this.fetchEnumValues('YesNoNonVerifiable');
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  gap: 1em;
  flex-flow: row wrap;
  flex-basis: 100%;
  justify-content: flex-start;
}

.flex > div {
  flex: 1;
  min-width: 300px;
}

/** accordion */
.accordion button {
  text-align: left;
  padding: 0.8em 0.5em;
}
.accordion .card,
.accordion .card-header {
  border: 0;
  border-radius: 0em;
}
.accordion .btn.btn-block {
  border: 0;
  border-radius: 0em;
  font-weight: bold;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.accordion {
  position: relative;
  overflow: hidden;
}
</style>
