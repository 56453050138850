<template>
  <div>
    <AccordionCard :state="cards.documentationProjectReportAvailableState">
      <template v-slot:header>
        {{ $t('documentationProjectReportAvailableState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('documentationProjectReportAvailableState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.documentationProjectReportAvailableState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.documentationProjectReportAvailableNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[79].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.documentationProjectReportAvailableAttachments"
              attachmentType="documentationProjectReportAvailableAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isDocumentationProjectReportAvailableAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="documentationProjectReportAvailableAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isDocumentationProjectReportAvailableAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.specialCustomerRequestsDocumentedState">
      <template v-slot:header>
        {{ $t('specialCustomerRequestsDocumentedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('specialCustomerRequestsDocumentedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.specialCustomerRequestsDocumentedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.specialCustomerRequestsDocumentedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[80].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.specialCustomerRequestsDocumentedAttachments"
              attachmentType="specialCustomerRequestsDocumentedAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isSpecialCustomerRequestsDocumentedAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="specialCustomerRequestsDocumentedAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isSpecialCustomerRequestsDocumentedAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.tileTypeAndWidthDocumentedState">
      <template v-slot:header>
        {{ $t('tileTypeAndWidthDocumentedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('tileTypeAndWidthDocumentedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.tileTypeAndWidthDocumentedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.tileTypeAndWidthDocumentedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[81].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.tileTypeAndWidthDocumentedAttachments"
              attachmentType="tileTypeAndWidthDocumentedAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isTileTypeAndWidthDocumentedAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="tileTypeAndWidthDocumentedAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isTileTypeAndWidthDocumentedAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.replacementTileInstalledState">
      <template v-slot:header>
        {{ $t('replacementTileInstalledState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('replacementTileInstalledState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.replacementTileInstalledState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.replacementTileInstalledNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[82].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.replacementTileInstalledAttachments"
              attachmentType="replacementTileInstalledAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isReplacementTileInstalledAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="replacementTileInstalledAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isReplacementTileInstalledAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.lightningProtectionPresentState">
      <template v-slot:header>
        {{ $t('lightningProtectionPresentState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('lightningProtectionPresentState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.lightningProtectionPresentState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.lightningProtectionPresentNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[83].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.lightningProtectionPresentAttachments"
              attachmentType="lightningProtectionPresentAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isLightningProtectionPresentAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="lightningProtectionPresentAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isLightningProtectionPresentAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';
export default {
  name: 'QualityReportAccordions',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
    DropzoneUpload,
    AttachmentGallery,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isDocumentationProjectReportAvailableAttachmentsUploadComplete: false,
      isSpecialCustomerRequestsDocumentedAttachmentsUploadComplete: false,
      isTileTypeAndWidthDocumentedAttachmentsUploadComplete: false,
      isReplacementTileInstalledAttachmentsUploadComplete: false,
      isLightningProtectionPresentAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isDocumentationProjectReportAvailableAttachmentsUploadComplete = true;
          this.isSpecialCustomerRequestsDocumentedAttachmentsUploadComplete = true;
          this.isTileTypeAndWidthDocumentedAttachmentsUploadComplete = true;
          this.isReplacementTileInstalledAttachmentsUploadComplete = true;
          this.isLightningProtectionPresentAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
