<template>
  <div>
    <AccordionCard :state="cards.substructureTensionFreeCheckedState">
      <template v-slot:header>
        {{ $t('substructureTensionFreeCheckedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('substructureTensionFreeCheckedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.substructureTensionFreeCheckedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.substructureTensionFreeCheckedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.substructureInstalledAsPerReportState">
      <template v-slot:header>
        {{ $t('substructureInstalledAsPerReportState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('substructureInstalledAsPerReportState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.substructureInstalledAsPerReportState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.substructureInstalledAsPerReportNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.eachRailSecuredWithMinTwoHooksState">
      <template v-slot:header>
        {{ $t('eachRailSecuredWithMinTwoHooksState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('eachRailSecuredWithMinTwoHooksState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.eachRailSecuredWithMinTwoHooksState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.eachRailSecuredWithMinTwoHooksNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[87].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.eachRailSecuredWithMinTwoHooksAttachments"
              attachmentType="eachRailSecuredWithMinTwoHooksAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isEachRailSecuredWithMinTwoHooksAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="eachRailSecuredWithMinTwoHooksAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isEachRailSecuredWithMinTwoHooksAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.distanceBetweenHooksMoreThan140cmState">
      <template v-slot:header>
        {{ $t('distanceBetweenHooksMoreThan140cmState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('distanceBetweenHooksMoreThan140cmState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.distanceBetweenHooksMoreThan140cmState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.distanceBetweenHooksMoreThan140cmNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.correctRoofHooksUsedState">
      <template v-slot:header>
        {{ $t('correctRoofHooksUsedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('correctRoofHooksUsedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.correctRoofHooksUsedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.correctRoofHooksUsedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.distanceRoofHookToTile35mmState">
      <template v-slot:header>
        {{ $t('distanceRoofHookToTile35mmState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('distanceRoofHookToTile35mmState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.distanceRoofHookToTile35mmState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.distanceRoofHookToTile35mmNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[88].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.distanceRoofHookToTile35mmAttachments"
              attachmentType="distanceRoofHookToTile35mmAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isDistanceRoofHookToTile35mmAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="distanceRoofHookToTile35mmAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isDistanceRoofHookToTile35mmAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.minScrewDistanceToRafterEdge24mmState">
      <template v-slot:header>
        {{ $t('minScrewDistanceToRafterEdge24mmState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('minScrewDistanceToRafterEdge24mmState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.minScrewDistanceToRafterEdge24mmState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.minScrewDistanceToRafterEdge24mmNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[89].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.minScrewDistanceToRafterEdge24mmAttachments"
              attachmentType="minScrewDistanceToRafterEdge24mmAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isMinScrewDistanceToRafterEdge24mmAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="minScrewDistanceToRafterEdge24mmAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isMinScrewDistanceToRafterEdge24mmAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.roofHookProperlyFixedToRafterState">
      <template v-slot:header>
        {{ $t('roofHookProperlyFixedToRafterState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('roofHookProperlyFixedToRafterState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.roofHookProperlyFixedToRafterState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.roofHookProperlyFixedToRafterNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.overhangObservedState">
      <template v-slot:header>
        {{ $t('overhangObservedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('overhangObservedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.overhangObservedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.overhangObservedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[90].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.overhangObservedAttachments"
              attachmentType="overhangObservedAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isOverhangObservedAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="overhangObservedAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="isOverhangObservedAttachmentsUploadComplete = false"
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.moduleFieldProperlyGroundedState">
      <template v-slot:header>
        {{ $t('moduleFieldProperlyGroundedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('moduleFieldProperlyGroundedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.moduleFieldProperlyGroundedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.moduleFieldProperlyGroundedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[91].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.moduleFieldProperlyGroundedAttachments"
              attachmentType="moduleFieldProperlyGroundedAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isModuleFieldProperlyGroundedAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="moduleFieldProperlyGroundedAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isModuleFieldProperlyGroundedAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';

export default {
  name: 'SubstructureQualityReportAccordions',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
    DropzoneUpload,
    AttachmentGallery,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isDistanceRoofHookToTile35mmAttachmentsUploadComplete: false,
      isMinScrewDistanceToRafterEdge24mmAttachmentsUploadComplete: false,
      isOverhangObservedAttachmentsUploadComplete: false,
      isModuleFieldProperlyGroundedAttachmentsUploadComplete: false,
      isEachRailSecuredWithMinTwoHooksAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isDistanceRoofHookToTile35mmAttachmentsUploadComplete = true;
          this.isMinScrewDistanceToRafterEdge24mmAttachmentsUploadComplete = true;
          this.isOverhangObservedAttachmentsUploadComplete = true;
          this.isModuleFieldProperlyGroundedAttachmentsUploadComplete = true;
          this.isEachRailSecuredWithMinTwoHooksAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
